import { ReportCategoryChartConfig } from "../types";
import { getChangedMetricsAllTrend } from "../../../../_common/charts/config/getChangedMetricsAllTrend";
import { getCrawlSourceOverlapChart } from "../../../../_common/charts/config/getCrawlSourceOverlapChart";
import { getGscTrafficFunnel } from "../../../../_common/charts/config/getGscTrafficFunnel";
import { getHttpAndHttpsChart } from "../../../../_common/charts/config/getHttpAndHttpsChart";
import { getNon200PagesChart } from "../../../../_common/charts/config/getNon200PagesChart";
import { getNonIndexablePagesChart } from "../../../../_common/charts/config/getNonIndexablePagesChart";
import { getOrphanedPagesReportChart } from "../../../../_common/charts/config/getOrphanedPagesReportChart";
import { getPagesBreakdownChartWithMoreLink } from "../../../../_common/charts/config/getPagesBreakdownSingle";
import { getPagesBreakdownTrendChart } from "../../../../_common/charts/config/getPagesBreakdownTrendChart";
import { getSearchClicksByDeviceChart } from "../../../../_common/charts/config/getSearchClicksByDeviceChart";
import { getUncrawledUrlsBreakdownChart } from "../../../../_common/charts/config/getUncrawledUrlsBreakdownChart";
import { getWebCrawlDepthChart } from "../../../../_common/charts/config/getWebCrawlDepthChart";

export const generateNoCategoryChartConfigItems: ReportCategoryChartConfig = {
  includeTopChanges: true,
  mainCharts: [
    getPagesBreakdownChartWithMoreLink,
    getNon200PagesChart,
    getNonIndexablePagesChart,
    getUncrawledUrlsBreakdownChart,
    getOrphanedPagesReportChart,
    getChangedMetricsAllTrend,
    getCrawlSourceOverlapChart,
    getPagesBreakdownTrendChart,
    getWebCrawlDepthChart,
    getHttpAndHttpsChart,
    getGscTrafficFunnel,
    getSearchClicksByDeviceChart,
  ],
};

import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityStructureAAChart } from "../../../../../_common/charts/config/getAccessibilityStructureAAChart";
import { getAccessibilityStructureAATrend } from "../../../../../_common/charts/config/getAccessibilityStructureAATrend";

export const generateAccessibilityStructureAACategoryItems: ReportCategoryChartConfig =
  {
    includeTopChanges: true,
    mainCharts: [
      getAccessibilityStructureAAChart,
      getAccessibilityStructureAATrend,
    ],
  };

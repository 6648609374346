import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import { ChartPanel } from "../../_common/charts/components/chart-components/ChartPanel";
import { useReportCategoryChartConfig } from "./config/useReportCategoryChartConfig";
import { useSession } from "@lumar/shared";
import {
  ChartConfigItem,
  TileChartConfigItem,
} from "../../_common/charts/types/ChartConfig";
import { CurrentCategoryHealthScoreSection } from "./data-visualization/charts/health-score/CurrentCategoryHealthScoreSection";
import { TopChangesChart } from "./data-visualization/charts/TopChangesChart";
import { URLFunnel } from "./data-visualization/charts/url-funnel/URLFunnel";
import { TopErrorsChartPanel } from "./data-visualization/charts/TopErrorsChart";
import { useCrawlOverviewContext } from "../CrawlOverviewContext";
import { ChartData } from "../../_common/charts/components/chart-components/ChartData";
import { useChartDataContext } from "../../_common/charts/components/chart-components/ChartDataContext";
import {
  ChartComponent,
  ChartComponentProps,
} from "../../_common/charts/components/chart-components/ChartComponent";
import { ChartSkeleton } from "../../_common/charts/components/chart-components/ChartSkeleton";
import { TileChartComponent } from "./data-visualization/charts/tile-chart/TileChartComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "7px",
  },
  tileContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 15,
    marginTop: "16px",
    width: "100%",
    justifyContent: "center",
  },
  tile: {
    display: "block",
    background: theme.palette.background.paper,
    borderRadius: 8,
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    flexGrow: 1,
    flexBasis: 0,
    color: "inherit",
    padding: 8,
    height: 101,
    minWidth: 210,
    [theme.breakpoints.down("md")]: {
      minWidth: 292,
      maxWidth: "100%",
    },
  },
}));

export function Dashboard(): JSX.Element | null {
  const { data } = useCrawlOverviewContext();
  const classes = useStyles();
  const { tileCharts, mainCharts, includeTopChanges } =
    useReportCategoryChartConfig();
  const session = useSession();

  return (
    <div style={{ marginTop: 14 }}>
      <CurrentCategoryHealthScoreSection />
      {tileCharts.map((item, idx) => (
        <div key={idx} className={classes.tileContainer}>
          {item.map((chartConfigItem, idx) => (
            <div className={classes.tile} key={idx}>
              <ChartData {...chartConfigItem}>
                <TileChartRenderer {...chartConfigItem} />
              </ChartData>
            </div>
          ))}
        </div>
      ))}
      {data?.selectedCategory?.code === "top" ? <URLFunnel /> : null}
      <Grid container spacing={2} className={classes.root}>
        {includeTopChanges ? (
          <Grid key="top-changes" item xs={12} lg={6}>
            <TopChangesChart />
          </Grid>
        ) : null}
        {!session.hasAddon("health-scores") ? (
          <Grid item xs={12} lg={6}>
            <TopErrorsChartPanel />
          </Grid>
        ) : null}
        {mainCharts.map((chartConfigItem) => {
          const key = getKey(chartConfigItem);
          return (
            <ChartData key={key} {...chartConfigItem}>
              <ChartRender {...chartConfigItem} />
            </ChartData>
          );
        })}
      </Grid>
    </div>
  );
}

function ChartRender(props: ChartComponentProps): JSX.Element {
  const { reportStats, loading } = useChartDataContext();

  if (
    (props.hideChartWithoutData && !reportStats.length) ||
    (props.hideChartWithoutData && loading)
  ) {
    return <></>;
  }

  return (
    <Grid item xs={12} lg={6}>
      <ChartPanel>
        {loading ? <ChartSkeleton /> : <ChartComponent {...props} />}
      </ChartPanel>
    </Grid>
  );
}

function TileChartRenderer(props: TileChartConfigItem): JSX.Element {
  const { loading } = useChartDataContext();

  return (
    <>
      {loading ? (
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
          <div style={{ margin: "auto" }}>
            <CircularProgress />
          </div>
        </div>
      ) : (
        <TileChartComponent {...props} />
      )}
    </>
  );
}

function getKey(config: ChartConfigItem): string {
  return config.title([]) + config.visualisationType + (config.icon ?? "");
}

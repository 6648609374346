import { ReportCategoryChartConfig } from "../types";
import { getHreflangBreakdownChart } from "../../../../_common/charts/config/getHreflangBreakdownChart";
import { getHreflangBreakdownTrend } from "../../../../_common/charts/config/getHreflangBreakdownTrend";
import { getHreflangCombinationsTrend } from "../../../../_common/charts/config/getHreflangCombinationsTrend";
import { getHreflangIssuesChart } from "../../../../_common/charts/config/getHreflangIssuesChart";
import { getHreflangIssuesTrend } from "../../../../_common/charts/config/getHreflangIssuesTrend";
import { getHreflangLinksTrend } from "../../../../_common/charts/config/getHreflangLinksTrend";

export const generateCrawlabilityInternationalizationCategoryConfig: ReportCategoryChartConfig =
  {
    includeTopChanges: true,
    mainCharts: [
      getHreflangBreakdownChart,
      getHreflangBreakdownTrend,
      getHreflangIssuesChart,
      getHreflangIssuesTrend,
      getHreflangLinksTrend,
      getHreflangCombinationsTrend,
    ],
  };

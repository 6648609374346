import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilitySemanticsAAAChart } from "../../../../../_common/charts/config/getAccessibilitySemanticsAAAChart";
import { getAccessibilitySemanticsAAATrend } from "../../../../../_common/charts/config/getAccessibilitySemanticsAAATrend";

export const generateAccessibilitySemanticsAAACategoryItems: ReportCategoryChartConfig =
  {
    includeTopChanges: true,
    mainCharts: [
      getAccessibilitySemanticsAAAChart,
      getAccessibilitySemanticsAAATrend,
    ],
  };

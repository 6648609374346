import { ReportCategoryChartConfig } from "../types";
import { getBotHitsByDeviceChart } from "../../../../_common/charts/config/getBotHitsByDeviceChart";
import { getBotHitsByDeviceTrend } from "../../../../_common/charts/config/getBotHitsByDeviceTrend";
// import { getCumulativeLayoutShiftChart } from "../../../../_common/charts/config/getCumulativeLayoutShiftChart";
// import { getDomContentLoadChart } from "../../../../_common/charts/config/getDomContentLoadChart";
// import { getDomContentLoadTrend } from "../../../../_common/charts/config/getDomContentLoadTrend";
import { getExperienceChart } from "../../../../_common/charts/config/getExperienceChart";
// import { getFirstContentfulPaintChart } from "../../../../_common/charts/config/getFirstContentfulPaintChart";
// import { getFirstContentfulPaintTrend } from "../../../../_common/charts/config/getFirstContentfulPaintTrend";
import { getHttpStatusBreakdownChart } from "../../../../_common/charts/config/getHttpStatusBreakdownChart";
import { getHttpStatusTrend } from "../../../../_common/charts/config/getHttpStatusTrend";
import { getIndexabilityChart } from "../../../../_common/charts/config/getIndexabilityChart";
import { getIndexablePagesBreakdownChart } from "../../../../_common/charts/config/getIndexablePagesBreakdownChart";
import { getIndexablePagesBreakdownTrend } from "../../../../_common/charts/config/getIndexablePagesBreakdownTrend";
import { getIssuesChartIndexation } from "../../../../_common/charts/config/getIssuesChartIndexation";
// import { getLargestContentfulPaintChart } from "../../../../_common/charts/config/getLargestContentfulPaintChart";
// import { getLargestContentfulPaintTrend } from "../../../../_common/charts/config/getLargestContentfulPaintTrend";
import { getListBreakdownChart } from "../../../../_common/charts/config/getListBreakdownChart";
import { getLogSummaryBreakdownChart } from "../../../../_common/charts/config/getLogSummaryBreakdownChart";
import { getMobileIssuesTrend } from "../../../../_common/charts/config/getMobileIssuesTrend";
import { getNewsArticleStructuredDataValidationBreakdownTrend } from "../../../../_common/charts/config/getNewsArticleStructuredDataValidationBreakdownTrend";
import { getNon200PagesbyStatusCode } from "../../../../_common/charts/config/getNon200PagesbyStatusCode";
import { getNon200PagesDepthChart } from "../../../../_common/charts/config/getNon200PagesDepthChart";
import { getPageIssuesChart } from "../../../../_common/charts/config/getPageIssuesChart";
import { getPaginationChart } from "../../../../_common/charts/config/getPaginationChart";
import { getPaginationTrend } from "../../../../_common/charts/config/getPaginationTrend";
// import { getPerformanceBreakdownChart } from "../../../../_common/charts/config/getPerformanceBreakdownChart";
// import { getPerformanceBreakdownTrend } from "../../../../_common/charts/config/getPerformanceBreakdownTrend";
import { getProductStructuredDataValidationTrend } from "../../../../_common/charts/config/getProductStructuredDataValidationBreakdownTrend";
import { getResourceIssuesChart } from "../../../../_common/charts/config/getResourceIssuesChart";
import { getResourceIssuesTrend } from "../../../../_common/charts/config/getResourceIssuesTrend";
import { getSearchImpressionDistributionChart } from "../../../../_common/charts/config/getSearchImpressionDistributionChart";
// import { getServerResponseTimeChart } from "../../../../_common/charts/config/getServerResponseTimeChart";
// import { getServerResponseTimeTrend } from "../../../../_common/charts/config/getServerResponseTimeTrend";
// import { getTimeToInteractiveChart } from "../../../../_common/charts/config/getTimeToInteractiveChart";
// import { getTimeToInteractiveTrend } from "../../../../_common/charts/config/getTimeToInteractiveTrend";
import { getUniquenessChart } from "../../../../_common/charts/config/getUniquenessChart";
import { getUniquenessTrend } from "../../../../_common/charts/config/getUniquenessTrend";
import { getUrlDesignIssuesChart } from "../../../../_common/charts/config/getUrlDesignIssuesChart";
import { getUrlDesignIssuesTrend } from "../../../../_common/charts/config/getUrlDesignIssuesTrend";
import { getUrlFetchTimeChart } from "../../../../_common/charts/config/getUrlFetchTimeChart";
import { getUrlFetchTimeTrend } from "../../../../_common/charts/config/getUrlFetchTimeTrend";
import { getWebCrawlBreakdownChart } from "../../../../_common/charts/config/getWebCrawlBreakdownChart";

export const generateRemovedCategoryItems: ReportCategoryChartConfig = {
  includeTopChanges: true,
  mainCharts: [
    // getBotHitsBreakdownDepthChart,
    getBotHitsByDeviceChart,
    getBotHitsByDeviceTrend,
    // getCrawlSourceBacklinksTrend,
    // getCrawlSourceListTrend,
    // getCrawlSourceLogSummaryTrend,
    // getCrawlSourceWebTrend,
    // getCumulativeLayoutShiftChart,
    // getDomContentLoadChart,
    getExperienceChart,
    // getFirstContentfulPaintChart,
    getHttpStatusBreakdownChart,
    getHttpStatusTrend,
    getIndexabilityChart,
    getIndexablePagesBreakdownChart,
    getIndexablePagesBreakdownTrend,
    getIssuesChartIndexation,
    // getLargestContentfulPaintChart,
    getListBreakdownChart,
    getLogSummaryBreakdownChart,
    getMobileIssuesTrend,
    getNewsArticleStructuredDataValidationBreakdownTrend,
    getNon200PagesbyStatusCode,
    getNon200PagesDepthChart,
    getPageIssuesChart,
    getPaginationChart,
    getPaginationTrend,
    // getPerformanceBreakdownChart,
    getProductStructuredDataValidationTrend,
    getResourceIssuesChart,
    getResourceIssuesTrend,
    getSearchImpressionDistributionChart,
    // getServerResponseTimeChart,
    // getTimeToInteractiveChart,
    getUniquenessChart,
    getUniquenessTrend,
    getUrlDesignIssuesChart,
    getUrlDesignIssuesTrend,
    getUrlFetchTimeChart,
    getUrlFetchTimeTrend,
    getWebCrawlBreakdownChart,
  ],
};

import { ReportCategoryChartConfig } from "../types";
import { getContentDuplicationChart } from "../../../../_common/charts/config/getContentDuplicationChart";
import { getContentDuplicationTrend } from "../../../../_common/charts/config/getContentDuplicationTrend";
import { getTitleAndDescriptionIssuesChart } from "../../../../_common/charts/config/getTitleAndDescriptionIssuesChart";
import { getTitleAndDescriptionIssuesTrend } from "../../../../_common/charts/config/getTitleAndDescriptionIssuesTrend";

export const generateRankabilityTitlesDescriptionsCategory: ReportCategoryChartConfig =
  {
    includeTopChanges: true,
    mainCharts: [
      getTitleAndDescriptionIssuesChart,
      getTitleAndDescriptionIssuesTrend,
      getContentDuplicationChart,
      getContentDuplicationTrend,
    ],
  };

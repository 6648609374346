import { useTranslation } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  title: { fontSize: "12px", color: "black", fontWeight: 700 },
  message: { fontSize: "12px", color: "black", fontWeight: 400 },
}));

export const FailureRateReasons = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation("crawlProgress");

  return (
    <span className={classes.title}>
      {t("pauseReasons.failureRateThresholdTitle")}{" "}
      <span className={classes.message}>
        {t("pauseReasons.failureRateThresholdMessage")}
      </span>
    </span>
  );
};

import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilitySemanticsAChart } from "../../../../../_common/charts/config/getAccessibilitySemanticsAChart";
import { getAccessibilitySemanticsATrend } from "../../../../../_common/charts/config/getAccessibilitySemanticsATrend";

export const generateAccessibilitySemanticsACategoryItems: ReportCategoryChartConfig =
  {
    includeTopChanges: true,
    mainCharts: [
      getAccessibilitySemanticsAChart,
      getAccessibilitySemanticsATrend,
    ],
  };

import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityKeyboardBPChart } from "../../../../../_common/charts/config/getAccessibilityKeyboardBPChart";
import { getAccessibilityKeyboardBPTrend } from "../../../../../_common/charts/config/getAccessibilityKeyboardBPTrend";

export const generateAccessibilityKeyboardBPCategoryItems: ReportCategoryChartConfig =
  {
    includeTopChanges: true,
    mainCharts: [
      getAccessibilityKeyboardBPChart,
      getAccessibilityKeyboardBPTrend,
    ],
  };

import { ReportCategoryChartConfig } from "../types";
import { getBotHitsCrawlBudget } from "../../../../_common/charts/config/getBotHitsCrawlBudget";
import { getCrawlabilityChart } from "../../../../_common/charts/config/getCrawlabilityChart";
import { getCrawlabilityTrend } from "../../../../_common/charts/config/getCrawlabilityTrend";
import { getLinksBreakdownChart } from "../../../../_common/charts/config/getLinksBreakdownChart";
import { getPrimaryPagesNotInSerps } from "../../../../_common/charts/config/getPrimaryPagesNotInSerps";
import { getPrimaryPagesNotInSerpsTrend } from "../../../../_common/charts/config/getPrimaryPagesNotInSerpsTrend";
import { getRedirectBreakdownChart } from "../../../../_common/charts/config/getRedirectBreakdownChart";
import { getSitemapsChart } from "../../../../_common/charts/config/getSitemapsChart";

export const generateCrawlabilityCategoryChartConfigItems: ReportCategoryChartConfig =
  {
    includeTopChanges: true,
    mainCharts: [
      getCrawlabilityChart,
      getCrawlabilityTrend,
      getPrimaryPagesNotInSerps,
      getPrimaryPagesNotInSerpsTrend,
      getBotHitsCrawlBudget,
      getLinksBreakdownChart,
      getRedirectBreakdownChart,
      getSitemapsChart,
    ],
  };

import { useTranslation, useDateFormatter } from "@lumar/shared";
import { useTheme } from "@material-ui/core";
import { generateCustomReportSegmentComparisonChart } from "../_common/charts/config/getCustomReportSegmentComparisonChart";
import { generateTopSegmentsCustomReportChart } from "../_common/charts/config/getTopSegmentsCustomReportChart";
import { ChartConfig } from "../_common/charts/types/ChartConfig";
import { generateSingleCustomReportTrendChart } from "../_common/charts/utils/generateSingleCustomReportTrendChartConfig";
import { useCrawlContext } from "../crawl-overview/CrawlContext";
import { DatasourceCode } from "../graphql";
import { SUPPORTED_SEGMENT_CHART_CAROUSEL_DATASOURCES } from "../_common/constants";

export function useCustomReportChartConfig(
  customReportTemplateId: string,
  datasourceCodeEnum?: DatasourceCode,
): ChartConfig[] {
  const { t } = useTranslation("charts");
  const theme = useTheme();
  const dateFormatter = useDateFormatter();
  const crawlContext = useCrawlContext();

  const trendConfig = generateSingleCustomReportTrendChart(
    customReportTemplateId,
  )(t, theme, dateFormatter);

  // datasourceCode might not exist because the query might be in loading state
  const isSegmentationSupported =
    datasourceCodeEnum &&
    SUPPORTED_SEGMENT_CHART_CAROUSEL_DATASOURCES.includes(datasourceCodeEnum);
  const hasSegments = (crawlContext?.data?.crawlSegments.length ?? 0) > 0;
  const isSegmentSelected = !!crawlContext?.data?.selectedCrawlSegment;

  if (isSegmentationSupported && hasSegments) {
    const segmentChartConfig = isSegmentSelected
      ? generateCustomReportSegmentComparisonChart(customReportTemplateId)(t)
      : generateTopSegmentsCustomReportChart(customReportTemplateId)(t);

    return [segmentChartConfig, trendConfig];
  }

  return [trendConfig];
}

import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityNameRoleValueBPChart } from "../../../../../_common/charts/config/getAccessibilityNameRoleValueBPChart";
import { getAccessibilityNameRoleValueBPTrend } from "../../../../../_common/charts/config/getAccessibilityNameRoleValueBPTrend";

export const generateAccessibilityNameRoleValueBPCategoryItems: ReportCategoryChartConfig =
  {
    includeTopChanges: true,
    mainCharts: [
      getAccessibilityNameRoleValueBPChart,
      getAccessibilityNameRoleValueBPTrend,
    ],
  };

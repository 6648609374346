import { ReportCategoryChartConfig } from "../types";
import { getCrawlSourceSearchConsoleTrend } from "../../../../_common/charts/config/getCrawlSourceSearchConsoleTrend";
import { getGoogleSearchConsoleBreakdownChart } from "../../../../_common/charts/config/getGoogleSearchConsoleBreakdownChart";
import { getGscTrafficFunnel } from "../../../../_common/charts/config/getGscTrafficFunnel";
import { getGscTrafficFunnelTrend } from "../../../../_common/charts/config/getGscTrafficFunnelTrend";
import { getGscTrafficLevel } from "../../../../_common/charts/config/getGscTrafficLevel";
import { getGscTrafficLevelTrend } from "../../../../_common/charts/config/getGscTrafficLevelTrend";
import { getSearchClicksByDeviceChart } from "../../../../_common/charts/config/getSearchClicksByDeviceChart";

export const generateTrafficSearchConsoleCategoryConfigItems: ReportCategoryChartConfig =
  {
    includeTopChanges: true,
    mainCharts: [
      getGscTrafficFunnel,
      getGscTrafficFunnelTrend,
      getGscTrafficLevel,
      getGscTrafficLevelTrend,
      getGoogleSearchConsoleBreakdownChart,
      getCrawlSourceSearchConsoleTrend,
      getSearchClicksByDeviceChart,
    ],
  };

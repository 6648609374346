import {
  ChartCarousel,
  ChartCarouselSkeleton,
} from "../../_common/charts/chart-carousel/ChartCarousel";
import { ChartConfig } from "../../_common/charts/types/ChartConfig";

interface ReportChartCarouselProps {
  chartConfigs: ChartConfig[] | null;
  loading?: boolean;
}

export function ReportChartCarousel({
  loading,
  chartConfigs,
}: ReportChartCarouselProps): JSX.Element | null {
  if (loading) {
    return <ChartCarouselSkeleton />;
  }
  return <ChartCarousel config={chartConfigs} />;
}

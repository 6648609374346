/* eslint-disable fp/no-this */
import { Box, makeStyles, useTheme } from "@material-ui/core";
import Highcharts from "highcharts";
import React from "react";
import { useHistory } from "react-router-dom";

import { renderToString } from "react-dom/server";
import { useTranslation } from "react-i18next";
import { useCrawlContextData } from "../../../crawl-overview/CrawlContext";
import { ReportTemplateUnit } from "../../../graphql";
import { assert } from "../../assert";
import { useReportTemplateUnitFormatter } from "../../locale/format-api-enum/useReportTemplateUnitFormatter";
import { Routes } from "../../routing/routes";
import { useGenericParams } from "../../routing/useGenericParams";
import { useChartDataContext } from "../components/chart-components/ChartDataContext";
import { ChartRef, HighchartsChart } from "../components/HighchartsChart";
import { hasUnitProperty } from "../utils/hasUnitProperty";
import { useTooltipStyles } from "../utils/useTooltipStyles";
import {
  MultiSeriesProps,
  pointContainsReportNameInTooltip,
  pointContainsReportTemplateCode,
  pointContainsSegmentIdKey,
  pointContainsSegmentNameKey,
} from "./types";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    marginBottom: theme.spacing(1.75),
  },
}));

export const BarChart = React.forwardRef<ChartRef, MultiSeriesProps>(
  function Chart(props, ref): JSX.Element {
    const {
      showPercentageInSeriesTooltip,
      visualisationColors,
      title,
      icon,
      dontLinkReports,
    } = props;
    const classes = useStyles();
    const tooltipClasses = useTooltipStyles();
    const theme = useTheme();
    const { accountId, projectId, crawlId } = useGenericParams();
    const { selectedCrawlSegment } = useCrawlContextData();
    const history = useHistory();
    const { t } = useTranslation(["units", "charts"]);
    const { crawl, project, segmentName } = useChartDataContext();
    const formatUnit = useReportTemplateUnitFormatter();

    assert(accountId);
    assert(projectId);
    assert(crawlId);

    const options: Highcharts.Options = {
      chart: {
        type: "bar",
        spacingRight: 50,
        style: {
          fontSize: "1em",
          fontFamily: theme.typography.fontFamily,
        },
        events: {
          load: (event) => {
            assert(isChart(event.target));
            const { tooltip, series, xAxis } = event.target;

            Object.entries(xAxis[0].ticks).forEach(([index, tick]) => {
              tick.label?.on("mouseover", () => {
                if (series[0]) {
                  tooltip.refresh(series[0]?.data[index as unknown as number]);
                }
              });
            });
          },
        },
      },
      series: props.series.map((series) => ({ ...series, type: "bar" })),
      ...(visualisationColors ? { colors: visualisationColors } : {}),
      plotOptions: {
        bar: {
          grouping: false,
          minPointLength: 4,
          maxPointWidth: 20,
          pointPadding: 0,
          groupPadding: 0.1,
          borderRadius: {
            radius: theme.props?.BarChart?.borderRadius ?? 3,
            where: "all",
            scope: "point",
          },
          cursor: dontLinkReports ? "default" : "pointer",
          events: dontLinkReports
            ? {}
            : {
                click: (event) => {
                  const point = (event.point ??
                    event.target) as Highcharts.Point;

                  assert(pointContainsReportTemplateCode(point));
                  assert(pointContainsSegmentIdKey(point));

                  const urlParams = {
                    accountId,
                    projectId,
                    crawlId,
                    reportTemplateCode: point.reportTemplateCode,
                    segmentId:
                      point.segmentId || selectedCrawlSegment?.segment?.id,
                  };

                  const reportUrl =
                    props.serieLink?.(urlParams) ??
                    Routes.Report.getUrl({
                      ...urlParams,
                      reportTypeCode: "basic",
                    });

                  if (
                    event instanceof PointerEvent ||
                    event instanceof KeyboardEvent
                  ) {
                    if (event.ctrlKey || event.metaKey) {
                      window.open(reportUrl, "_blank", "noreferrer");
                    } else {
                      history.push(reportUrl);
                    }
                  }
                },
              },
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: "allow",
            style: {
              fontSize: "1em",
              fontWeight: theme.typography.fontWeightRegular?.toString(),
              textOutline: "none",
              fontVariantNumeric: "tabular-nums",
            },
            formatter: function () {
              return t("units:number", { count: this.y || 0 });
            },
          },
        },
      },
      xAxis: {
        type: "category",
        gridLineWidth: 0,
        lineWidth: 0,
        uniqueNames: false,
        labels: {
          useHTML: true,
          style: {
            fontSize: "14px",
            maxWidth: "275px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },
        },
      },
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: undefined,
        },
        labels: {
          enabled: false,
        },
        min: 0,
        minRange: 10,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          assert(pointContainsReportNameInTooltip(this));
          assert(pointContainsSegmentNameKey(this));
          const seriesTotal = this.series.data
            .map((point) => point.y ?? 0)
            .reduce((acc, cur) => acc + cur, 0);
          const percentage = ((this.y || 0) / seriesTotal) * 100;
          const segmentName = this.segmentName;

          return renderToString(
            <div className={tooltipClasses.container}>
              {showPercentageInSeriesTooltip ? (
                <span className={tooltipClasses.percentage}>
                  {t("units:percent", { count: (percentage || 0) / 100 })}
                </span>
              ) : null}
              <div className={tooltipClasses.textContainer}>
                <div className={tooltipClasses.urlCountText}>
                  {formatUnit(
                    this.y || 0,
                    hasUnitProperty(this) ? this.unit : ReportTemplateUnit.UrLs,
                  )}
                </div>
                <div>
                  {!segmentName
                    ? this.reportNameInTooltip
                    : t("charts:inSegment", {
                        report: this.reportNameInTooltip,
                        segment: segmentName,
                      })}
                </div>
              </div>
            </div>,
          );
        },
      },
      exporting: {
        csv: {
          columnHeaderFormatter: function (item?: { name?: string }) {
            return item?.name ?? "Report";
          },
        },
        chartOptions: {
          chart: { spacingLeft: 30, spacingRight: 50 },
        },
      },
    };

    return (
      <Box flexGrow={1} className={classes.wrapper}>
        <HighchartsChart
          options={options}
          ref={ref}
          title={title}
          icon={icon}
          segmentName={segmentName}
          projectName={project?.name}
          primaryDomain={project?.primaryDomain}
          crawlCreatedAt={crawl?.createdAt}
        />
      </Box>
    );
  },
);

function isChart(value: unknown): value is Highcharts.Chart {
  return value instanceof Highcharts.Chart;
}

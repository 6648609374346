import { ProgressDashboardContainer } from "../_common/progress-dashboard-container/ProgressDashboardContainer";
import React from "react";
import Highcharts, { Options } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "@lumar/shared";
import { isCrawling, isQeued } from "../../_common/running-crawl-query-utils";
import { makeStyles } from "@material-ui/core";
import { RunningCrawl } from "../../../useProjectPoller";

const useStyles = makeStyles(() => ({
  "@global": {
    "@keyframes dash": {
      to: { strokeDashoffset: -100 },
    },
  },
  container: (props: { isCrawling: boolean }) => ({
    "& path": {
      animation: props.isCrawling ? `dash 5s linear infinite` : "none",
    },
  }),
}));

export function CrawlFailureRateChart({
  failureRateChartOptions,
  runningCrawl,
}: {
  failureRateChartOptions: Options;
  runningCrawl?: RunningCrawl;
}): JSX.Element {
  const { t } = useTranslation("crawlProgress");
  const classes = useStyles({
    isCrawling: isCrawling(runningCrawl) || isQeued(runningCrawl),
  });

  return (
    <ProgressDashboardContainer
      header={t("progressDashboard.crawlFailureRateChartTitle")}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={failureRateChartOptions}
        containerProps={{
          className: classes.container,
          "data-testid": "crawl-failure-rate-chart",
        }}
      />
    </ProgressDashboardContainer>
  );
}

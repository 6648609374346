import { ReportCategoryChartConfig } from "../types";
import { getNonIndexableDepthChart } from "../../../../_common/charts/config/getNonIndexableDepthChart";
import { getNonIndexablePagesBySourceChart } from "../../../../_common/charts/config/getNonIndexablePagesBySourceChart";
import { getNonIndexablePagesBySourceTrend } from "../../../../_common/charts/config/getNonIndexablePagesbySourceTrend";
import { getNonIndexablePagesChart } from "../../../../_common/charts/config/getNonIndexablePagesChart";
import { getNonIndexablePagesTrend } from "../../../../_common/charts/config/getNonIndexablePagesTrend";

export const generateIndexabilityNoindexCategoryItems: ReportCategoryChartConfig =
  {
    includeTopChanges: true,
    mainCharts: [
      getNonIndexablePagesChart,
      getNonIndexablePagesTrend,
      getNonIndexablePagesBySourceChart,
      getNonIndexablePagesBySourceTrend,
      getNonIndexableDepthChart,
    ],
  };

import { useTranslation } from "@lumar/shared";
import { Tab, Tabs } from "@material-ui/core";
import { useCrawlContextHelpers } from "../../crawl-overview/CrawlContext";
import { CustomReportOverviewType } from "../_common/CustomReportTypes";
import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
    display: "flex",
    width: "100%",
  },
  tabs: { flexGrow: 1, height: 47 },
  content: {
    flexShrink: 0,
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
    paddingBottom: theme.spacing(0.875),
    borderBottomColor: theme.palette.grey[300],
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
  },
}));

type CustomReportsTabsProps = {
  selectedType: string;
  onTypeChange(type: string): void;
  children?: React.ReactNode | React.ReactNode[];
};

export function CustomReportsTabs(props: CustomReportsTabsProps): JSX.Element {
  const { t } = useTranslation("crawlOverview");
  const helpers = useCrawlContextHelpers();
  const classes = useStyles();

  const errorReports = helpers.getCrawlErrorCustomReportsList();
  const allReports = helpers.getCrawlCustomReportsList();

  return (
    <div className={classes.container}>
      <Tabs
        className={classes.tabs}
        value={props.selectedType}
        indicatorColor="primary"
        onChange={(_, type) => {
          props.onTypeChange(type);
        }}
      >
        <Tab
          label={
            <TabLabel name="All Custom Reports" count={allReports.length} />
          }
          value={CustomReportOverviewType.All}
          data-testid="all-custom-reports-tab"
        />
        <Tab
          label={
            <TabLabel name={t("tabs.errors")} count={errorReports.length} />
          }
          value={CustomReportOverviewType.Errors}
          data-testid="issue-custom-reports-tab"
        />
      </Tabs>
      <div className={classes.content}>{props.children}</div>
    </div>
  );
}

function TabLabel({
  name,
  count,
}: {
  name: string;
  count: number;
}): JSX.Element {
  const { t } = useTranslation("units");

  return (
    <span>
      {name}{" "}
      <span style={{ fontSize: "12px", lineHeight: "14.52px" }}>
        ({t("number", { count })})
      </span>
    </span>
  );
}

import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilitySensoryAndVisualAAChart } from "../../../../../_common/charts/config/getAccessibilitySensoryAndVisualAAChart";
import { getAccessibilitySensoryAndVisualAATrend } from "../../../../../_common/charts/config/getAccessibilitySensoryAndVisualAATrend";

export const generateAccessibilitySensoryAndVisualAACategoryItems: ReportCategoryChartConfig =
  {
    includeTopChanges: true,
    mainCharts: [
      getAccessibilitySensoryAndVisualAAChart,
      getAccessibilitySensoryAndVisualAATrend,
    ],
  };

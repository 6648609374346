import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityLanguageAAChart } from "../../../../../_common/charts/config/getAccessibilityLanguageAAChart";
import { getAccessibilityLanguageAATrend } from "../../../../../_common/charts/config/getAccessibilityLanguageAATrend";

export const generateAccessibilityLanguageAACategoryItems: ReportCategoryChartConfig =
  {
    includeTopChanges: true,
    mainCharts: [
      getAccessibilityLanguageAAChart,
      getAccessibilityLanguageAATrend,
    ],
  };

import { ReportCategoryChartConfig } from "../types";
import { getHttpAndHttpsChart } from "../../../../_common/charts/config/getHttpAndHttpsChart";
import { getHttpAndHttpsChartTrend } from "../../../../_common/charts/config/getHttpAndHttpsChartTrend";
import { getHttpsIssuesChart } from "../../../../_common/charts/config/getHttpsIssuesChart";
import { getHttpsIssuesTrend } from "../../../../_common/charts/config/getHttpsIssuesTrend";
import { getSecurityBreakdownChart } from "../../../../_common/charts/config/getSecurityBreakdownChart";
import { getSecurityBreakdownTrend } from "../../../../_common/charts/config/getSecurityBreakdownTrend";
import { getSecurityTrafficExperienceChart } from "../../../../_common/charts/config/getSecurityTrafficExperienceChart";
import { getSecurityTrafficExperienceTrend } from "../../../../_common/charts/config/getSecurityTrafficExperienceTrend";

export const generateExperienceSecurityCategoryChartConfig: ReportCategoryChartConfig =
  {
    includeTopChanges: true,
    mainCharts: [
      getHttpAndHttpsChart,
      getHttpAndHttpsChartTrend,
      getHttpsIssuesChart,
      getHttpsIssuesTrend,
      getSecurityTrafficExperienceChart,
      getSecurityTrafficExperienceTrend,
      getSecurityBreakdownChart,
      getSecurityBreakdownTrend,
    ],
  };

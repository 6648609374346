import { TopNavigation } from "../_common/top-navigation/TopNavigation";
import {
  useTranslation,
  getRawCrawlId,
  useAccountGuard,
  useProjectGuard,
  useSession,
} from "@lumar/shared";
import { useProjectPoller } from "./useProjectPoller";
import { ProjectCrawlsTabs } from "./ProjectCrawlsTabs";
import { useParams } from "react-router-dom";

export function ProjectCrawls(): JSX.Element {
  const { projectId } = useParams<{ projectId: string }>();
  const { t } = useTranslation(["crawlsList", "crawlProgress"]);
  const { isDeepCrawlAdminEnabled } = useSession();

  useAccountGuard();
  useProjectGuard({ skip: !projectId });

  const {
    project,
    runningCrawl,
    speedChartOptions,
    requestRateChartOptions,
    levelsChartOptions,
    failureRateChartOptions,
    responseCodesChartOptions,
  } = useProjectPoller();

  const lastFinishedCrawl = project?.getProject?.lastFinishedCrawl;
  const lastFinishedCrawlId = lastFinishedCrawl
    ? getRawCrawlId(lastFinishedCrawl.id)
    : undefined;

  function getTitle(): string | undefined {
    if (!projectId) return t("crawlProgress:tabs.new");

    const projectName = project?.getProject?.name;
    if (isDeepCrawlAdminEnabled && runningCrawl)
      return t("crawlProgress:tabs.adminTitle", {
        projectName,
        crawlId: runningCrawl.id,
      });

    return projectName;
  }

  return (
    <>
      <TopNavigation
        title={getTitle()}
        breadcrumbs={[{ label: t("crawlsList:pageTitle") }]}
        crawlId={lastFinishedCrawlId}
      />
      <ProjectCrawlsTabs
        project={project}
        runningCrawl={runningCrawl}
        speedChartOptions={speedChartOptions}
        requestRateChartOptions={requestRateChartOptions}
        levelsChartOptions={levelsChartOptions}
        failureRateChartOptions={failureRateChartOptions}
        responseCodesChartOptions={responseCodesChartOptions}
      />
    </>
  );
}

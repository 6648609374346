import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityIssuesandInstancesATrend } from "../../../../../_common/charts/config/getAccessibilityIssuesandInstancesATrend";
import { getAccessibilityLevelAChart } from "../../../../../_common/charts/config/getAccessibilityLevelAChart";
import { getAccessibilityLevelATrend } from "../../../../../_common/charts/config/getAccessibilityLevelATrend";
import { getAccessibilityPagesWithIssuesLevelAChart } from "../../../../../_common/charts/config/getAccessibilityPagesWithIssuesLevelAChart";
import { getAccessibilityPagesWithIssuesLevelATrend } from "../../../../../_common/charts/config/getAccessibilityPagesWithIssuesLevelATrend";

export const generateAccessibilityLevelACategoryItems: ReportCategoryChartConfig =
  {
    includeTopChanges: true,
    mainCharts: [
      getAccessibilityIssuesandInstancesATrend,
      getAccessibilityPagesWithIssuesLevelAChart,
      getAccessibilityPagesWithIssuesLevelATrend,
      getAccessibilityLevelAChart,
      getAccessibilityLevelATrend,
    ],
  };

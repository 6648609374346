/* eslint-disable fp/no-this, react/display-name */
import Highcharts from "highcharts";
import { unescape } from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";

import { makeStyles, useTheme } from "@material-ui/core";
import { renderToString } from "react-dom/server";
import { useTranslation } from "react-i18next";
import { useCrawlContextData } from "../../../crawl-overview/CrawlContext";
import { ReportTemplateUnit } from "../../../graphql";
import { assert } from "../../assert";
import { useReportTemplateUnitFormatter } from "../../locale/format-api-enum/useReportTemplateUnitFormatter";
import { Routes } from "../../routing/routes";
import { useGenericParams } from "../../routing/useGenericParams";
import { useChartDataContext } from "../components/chart-components/ChartDataContext";
import { ChartRef, HighchartsChart } from "../components/HighchartsChart";
import { hasUnitProperty } from "../utils/hasUnitProperty";
import { useTooltipStyles } from "../utils/useTooltipStyles";
import {
  isPoint,
  MultiSeriesProps,
  pointContainsLegendItem,
  pointContainsReportNameInTooltip,
  pointContainsReportTemplateCode,
  pointContainsSegmentNameKey,
} from "./types";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    height: "100%",
  },
  countInLegend: {
    fontWeight: 500,
    fontVariantNumeric: "tabular-nums",
    marginLeft: theme.spacing(1),
  },
  legendItem: {
    display: "flex",
    justifyContent: "space-between",
    width: 260,
    marginLeft: theme.spacing(0.75),
    [theme.breakpoints.down("xs")]: {
      width: 100,
    },
  },
  legendName: {
    width: "85%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

function isChart(value: unknown): value is Highcharts.Chart {
  return value instanceof Highcharts.Chart;
}

export const PieChart = React.forwardRef<ChartRef, MultiSeriesProps>(
  function PieChart(props, ref): JSX.Element {
    const { showPercentageInSeriesTooltip, title, icon, dontLinkReports } =
      props;
    const classes = useStyles();
    const tooltipClasses = useTooltipStyles();
    const theme = useTheme();
    const { accountId, projectId, crawlId } = useGenericParams();
    const { selectedCrawlSegment } = useCrawlContextData();
    const history = useHistory();
    const { t } = useTranslation(["units", "charts"]);
    const { crawl, project, segmentName } = useChartDataContext();
    const formatUnit = useReportTemplateUnitFormatter();

    assert(accountId);
    assert(projectId);
    assert(crawlId);

    const areAllReportsEmpty =
      props.series[0]?.data.reduce((acc, { y }) => acc + (y || 0), 0) === 0;

    const options: Highcharts.Options = {
      chart: {
        type: "pie",
        style: {
          fontSize: "1em",
          fontFamily: theme.typography.fontFamily,
        },
        events: {
          load: (event) => {
            assert(isChart(event.target));
            const { legend, tooltip, series } = event.target;

            legend.allItems.forEach((item, i) => {
              assert(isPoint(item));
              assert(pointContainsLegendItem(item));
              item.legendItem.label.on("mouseover", () => {
                if (series[0]) tooltip.refresh(series[0].data[i]);
              });
            });
          },
        },
      },
      series: props.series.map((series) => ({ ...series, type: "pie" })),
      plotOptions: {
        series: {
          states: {
            inactive: {
              opacity: 1,
            },
            hover: {
              brightness: 0,
            },
          },
        },
        pie: {
          size: "80%",
          innerSize: "50%",
          slicedOffset: 0,
          borderWidth: 0,
          showInLegend: true,
          cursor: areAllReportsEmpty || dontLinkReports ? "default" : "pointer",
          borderRadius: theme.props?.PieChart?.borderRadius ?? 3,
          dataLabels: {
            enabled: false,
          },
          point: {
            events: {
              legendItemClick: function (event) {
                event.preventDefault();
                event.browserEvent.stopPropagation();
                assert(pointContainsReportTemplateCode(event.target));

                if (!dontLinkReports) {
                  const reportUrl = Routes.Report.getUrl({
                    accountId,
                    projectId,
                    crawlId,
                    reportTemplateCode: event.target.reportTemplateCode,
                    reportTypeCode: "basic",
                    segmentId: selectedCrawlSegment?.segment?.id,
                  });

                  if (
                    event.browserEvent.ctrlKey ||
                    event.browserEvent.metaKey
                  ) {
                    window.open(reportUrl, "_blank", "noreferrer");
                  } else {
                    history.push(reportUrl);
                  }
                }
              },
            },
          },
          events: dontLinkReports
            ? {}
            : {
                click: function (event) {
                  const point = (event.point ??
                    event.target) as Highcharts.Point;

                  assert(pointContainsReportTemplateCode(point));

                  const urlParams = {
                    accountId,
                    projectId,
                    crawlId,
                    reportTemplateCode: point.reportTemplateCode,
                    segmentId: selectedCrawlSegment?.segment?.id,
                  };

                  const reportUrl =
                    props.serieLink?.(urlParams) ??
                    Routes.Report.getUrl({
                      ...urlParams,
                      reportTypeCode: "basic",
                    });

                  if (
                    event instanceof PointerEvent ||
                    event instanceof KeyboardEvent
                  ) {
                    if (event.ctrlKey || event.metaKey) {
                      window.open(reportUrl, "_blank", "noreferrer");
                    } else {
                      history.push(reportUrl);
                    }
                  }
                },
              },
        },
      },
      legend: {
        align: "left",
        verticalAlign: "top",
        layout: "vertical",
        padding: 0,
        margin: 0,
        x: 0,
        y: 5,
        itemMarginBottom: 16,
        symbolHeight: 12,
        itemStyle: {
          fontSize: theme.typography.pxToRem(14),
          fontWeight: theme.typography.fontWeightRegular?.toString(),
          cursor: areAllReportsEmpty || dontLinkReports ? "default" : "pointer",
        },
        labelFormatter: function () {
          assert(isPoint(this));
          return renderToString(
            <div className={classes.legendItem}>
              <span className={classes.legendName}>
                {unescape(this.options.name?.toString())}
              </span>
              <span className={classes.countInLegend}>
                {t("units:number", { count: this.y })}
              </span>
            </div>,
          );
        },
      },
      tooltip: {
        formatter: function () {
          assert(typeof this.percentage === "number");
          assert(pointContainsReportNameInTooltip(this));
          assert(pointContainsSegmentNameKey(this));

          const segmentName = this.segmentName;

          return renderToString(
            <div className={tooltipClasses.container}>
              {showPercentageInSeriesTooltip ? (
                <span className={tooltipClasses.percentage}>
                  {t("units:percent", { count: this.percentage / 100 })}
                </span>
              ) : null}
              <div className={tooltipClasses.textContainer}>
                <div className={tooltipClasses.urlCountText}>
                  {formatUnit(
                    this.y || 0,
                    hasUnitProperty(this) ? this.unit : ReportTemplateUnit.UrLs,
                  )}
                </div>
                <div>
                  {!segmentName
                    ? this.reportNameInTooltip
                    : t("charts:inSegment", {
                        report: this.reportNameInTooltip,
                        segment: segmentName,
                      })}
                </div>
              </div>
            </div>,
          );
        },
      },
      exporting: {
        csv: {
          columnHeaderFormatter: function (item?: { name?: string }) {
            return item?.name ?? "Report";
          },
        },
        chartOptions: {
          legend: {
            y: 70,
            labelFormatter: function () {
              assert(isPoint(this));
              return renderToString(
                <span>
                  {this.options.name} - {t("units:number", { count: this.y })}
                </span>,
              );
            },
          },
        },
      },
    };

    if (props.visualisationColors) {
      // eslint-disable-next-line fp/no-mutation
      options.colors = props.visualisationColors;
    }

    return (
      <div className={classes.wrapper}>
        <HighchartsChart
          options={options}
          ref={ref}
          title={title}
          icon={icon}
          segmentName={segmentName}
          projectName={project?.name}
          primaryDomain={project?.primaryDomain}
          crawlCreatedAt={crawl?.createdAt}
        />
      </div>
    );
  },
);

/* eslint-disable fp/no-this */
/* eslint-disable fp/no-mutating-methods */
/* eslint-disable fp/no-mutation */
import { Options } from "highcharts";
import { CrawlProgressFragment } from "../../../../graphql";
import { baseChartOptions } from "../_common/charts/base-chart-options";
import { baseTooltipOptions } from "../_common/charts/tooltip-config";
import { alpha, Theme } from "@material-ui/core";

function responseCodesChartOptions(): Options {
  return {
    ...baseChartOptions,
    chart: {
      height: 172,
      marginRight: 16,
    },
    xAxis: {
      type: "category",
    },
    yAxis: { type: "linear", title: { style: { display: "none" } } },
    legend: { enabled: false },
    tooltip: {
      ...baseTooltipOptions,
      formatter: function format() {
        return `${this.y || 0} URLs`;
      },
    },
    plotOptions: {
      column: {
        grouping: false,
      },
    },
  };
}

const redColumnsStatusCodes = new Set([
  "timeout",
  "408",
  "429",
  "502",
  "503",
  "504",
]);
const greenColumnsStatusCodes = new Set(["200"]);

const FIVE_MINUTES_IN_MS = 300 * 1000;

export function getResponseCodesChartOptions(args: {
  theme: Theme;
  crawl?: CrawlProgressFragment;
}): Options {
  const { theme, crawl } = args;
  const options = responseCodesChartOptions();

  const crawlRateAggregate = (crawl?.internalCrawlRates ?? [])
    .filter((rate) => {
      if (!rate.crawlingAt) return false;
      const lookbackWindowAgo = new Date(Date.now() - FIVE_MINUTES_IN_MS);
      return new Date(rate.crawlingAt) >= lookbackWindowAgo;
    })
    .reduce<Record<string, number>>((acc, curr) => {
      // Iterate through request stats for current crawl rate
      (curr.requestStats ?? []).forEach((stat) => {
        // Get status code key, defaulting to "timeout" if no status code
        const statusKey = stat.statusCode ? `${stat.statusCode}` : "timeout";

        // Add count to accumulated total for this status code
        acc[statusKey] = (acc[statusKey] ?? 0) + stat.count;
      });

      return acc;
    }, {});

  const timeoutData = Object.entries(crawlRateAggregate).find(
    ([code]) => code === "timeout",
  );

  const statusCodeData = Object.entries(crawlRateAggregate)
    .filter(([code]) => code !== "timeout")
    .sort(([a], [b]) => a.localeCompare(b));

  const series = [
    ...(timeoutData && timeoutData[1] > 0
      ? [
          {
            type: "column" as const,
            name: "timeout",
            color: alpha(theme.palette.red[500], 0.7),
            data: [["Timeout", crawlRateAggregate["timeout"]]],
          },
        ]
      : []),
    // Status code series in sorted order
    ...statusCodeData.map(([statusCode, count]) => ({
      type: "column" as const,
      name: statusCode,
      color: redColumnsStatusCodes.has(statusCode)
        ? alpha(theme.palette.red[500], 0.7)
        : greenColumnsStatusCodes.has(statusCode)
          ? alpha(theme.palette.green[500], 0.7)
          : alpha(theme.palette.grey[400], 0.7),
      data: [[statusCode, count]],
    })),
  ];

  return {
    ...options,
    series,
  };
}

import { Alert, Snackbar, useTranslation } from "@lumar/shared";
import { CircularProgress } from "@material-ui/core";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { useSearchParam } from "../_common/routing/useSearchParam";
import { TopNavigation } from "../_common/top-navigation/TopNavigation";
import { useCrawlContext } from "../crawl-overview/CrawlContext";
import { useCustomReportQuery } from "../graphql";
import { CustomReportGrid } from "./CustomReportGrid";
import { mapStringToReportTypeCode } from "./_common/CustomReportHelpers";
import { ReportChartCarousel } from "../report/report-chart-carousel/ReportChartCarousel";
import { CustomReportHeader } from "./CustomReportHeader";
import { useCustomReportChartConfig } from "./useCustomReportChartConfig";

export const CustomReport = (): JSX.Element => {
  const { t } = useTranslation("common");
  const { t: tCustomReports } = useTranslation("customReports");
  const { accountId, projectId, crawlId, customReportTemplateId } = useParams<{
    accountId: string;
    projectId: string;
    crawlId: string;
    customReportTemplateId: string;
  }>();
  const reportType = useSearchParam("reportType");

  const {
    data: crawlContextData,
    errors: crawlContextErrors,
    loading: crawlContextLoading,
    crawlNotFound,
  } = useCrawlContext();

  const { data, loading } = useCustomReportQuery({
    variables: {
      crawlId,
      customReportTemplateId,
      segmentId: crawlContextData?.selectedCrawlSegment?.segment.id,
    },
    onError: (error) => {
      enqueueSnackbar(<Snackbar title={error.message} variant="error" />);
    },
    fetchPolicy: "cache-first",
  });

  const datasourceCodeEnum =
    data?.customReport?.reportTemplate.datasourceCodeEnum;

  const chartConfigs = useCustomReportChartConfig(
    customReportTemplateId,
    datasourceCodeEnum,
  );

  if (crawlContextErrors) {
    return (
      <>
        <TopNavigation title={tCustomReports("pageTitle")} />
        <div style={{ marginTop: 15 }}>
          <Alert severity="error">{t("genericError")}</Alert>
        </div>
      </>
    );
  }

  if (crawlNotFound) {
    return (
      <>
        <TopNavigation title={tCustomReports("pageTitle")} />
        <div style={{ marginTop: 15 }}>
          <Alert severity="warning">{t("crawlNotFound")}</Alert>
        </div>
      </>
    );
  }

  const reportAdviceCollapsed = data?.me?.reportAdviceCollapsed;

  if (loading || crawlContextLoading) {
    return <CircularProgress style={{ marginTop: 15 }} />;
  }

  const customReport = data?.customReport;

  if (!customReport) {
    return (
      <>
        <TopNavigation title={tCustomReports("pageTitle")} />
        <div style={{ marginTop: 15 }}>
          <Alert severity="warning">
            {tCustomReports("customReportNotFound")}
          </Alert>
        </div>
      </>
    );
  }

  const isCustomReportTemplateDeleted =
    !crawlContextData?.projectAllCustomReportTemplates.find(
      (c) => c.id.split("_")?.[1] === customReport.customReportTemplate.rawID,
    );

  return (
    <>
      <CustomReportHeader
        title={customReport.customReportTemplate.name}
        accountId={accountId}
        projectId={projectId}
        crawlId={crawlId}
        customReportTemplate={customReport.customReportTemplate}
        baseReportTemplate={customReport.reportTemplate}
        reportAdviceCollapsed={Boolean(reportAdviceCollapsed)}
        isCustomReportTemplateDeleted={isCustomReportTemplateDeleted}
      />
      <ReportChartCarousel chartConfigs={chartConfigs} loading={loading} />
      <CustomReportGrid
        crawlId={crawlId}
        accountId={accountId}
        projectId={projectId}
        lastFinishedCrawlId={
          crawlContextData?.crawlProject.lastFinishedCrawl?.rawID
        }
        isCustomReportTemplateDeleted={isCustomReportTemplateDeleted}
        customReportTemplate={customReport.customReportTemplate}
        baseReportTemplate={customReport.reportTemplate}
        reportTypeCode={mapStringToReportTypeCode(reportType ?? "basic")}
        tabsMode={
          crawlContextData?.crawl.comparedTo
            ? "visible"
            : "onlyTotalRowsVisible"
        }
      />
    </>
  );
};

import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityIssuesandInstancesAAATrend } from "../../../../../_common/charts/config/getAccessibilityIssuesandInstancesAAATrend";
import { getAccessibilityLevelAAAChart } from "../../../../../_common/charts/config/getAccessibilityLevelAAAChart";
import { getAccessibilityLevelAAATrend } from "../../../../../_common/charts/config/getAccessibilityLevelAAATrend";
import { getAccessibilityPagesWithIssuesLevelAAAChart } from "../../../../../_common/charts/config/getAccessibilityPagesWithIssuesLevelAAAChart";
import { getAccessibilityPagesWithIssuesLevelAAATrend } from "../../../../../_common/charts/config/getAccessibilityPagesWithIssuesLevelAAATrend";

export const generateAccessibilityLevelAAACategoryItems: ReportCategoryChartConfig =
  {
    includeTopChanges: true,
    mainCharts: [
      getAccessibilityIssuesandInstancesAAATrend,
      getAccessibilityPagesWithIssuesLevelAAAChart,
      getAccessibilityPagesWithIssuesLevelAAATrend,
      getAccessibilityLevelAAAChart,
      getAccessibilityLevelAAATrend,
    ],
  };

/* eslint-disable fp/no-this, react/display-name */
import { Point } from "highcharts";
import { renderToString } from "react-dom/server";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useCrawlContextData } from "../../../crawl-overview/CrawlContext";
import { ConnectionPredicate, CrawlType } from "../../../graphql";
import { assert } from "../../assert";
import { createConnectionFilter } from "../../connection-filtering/createConnectionFilter";
import { Routes } from "../../routing/routes";
import { useGenericParams } from "../../routing/useGenericParams";
import { useChartDataContext } from "../components/chart-components/ChartDataContext";
import { useTooltipStyles } from "../utils/useTooltipStyles";
import { VennDiagramSeriesData } from "./ChartConfigItemVennDiagram";
import { crawlTypeOrder, getCrawlTypeName } from "./utils";
import { VennDiagramProps } from "./VennDiagram";
import { useTheme } from "@material-ui/core";
import { ChartConfigReportStatArray } from "../types/ChartConfigItemBase";

type DataPoint = {
  uniqueToSource: number;
  uniqueToCrawlType: number;
  overlap: number;
  missing: number;
};

export function useVennHighchartsOptions(
  props: VennDiagramProps,
  currentCrawlType: CrawlType,
): {
  options: Highcharts.Options;
  seriesData: VennDiagramSeriesData;
  missingMultipleSources: boolean;
} {
  const tooltipClasses = useTooltipStyles();
  const { t } = useTranslation("charts");
  const { t: tUnits } = useTranslation("units");
  const history = useHistory();
  const theme = useTheme();

  const { crawl, reportStats, totalUrls } = useChartDataContext();

  const { accountId, projectId, crawlId } = useGenericParams();
  const { selectedCrawlSegment } = useCrawlContextData();

  assert(accountId);
  assert(projectId);
  assert(crawlId);

  const linkData = {
    accountId,
    projectId,
    crawlId,
    segmentId: selectedCrawlSegment?.segment?.id,
  };

  const series = props.series(
    reportStats as ChartConfigReportStatArray,

    totalUrls,
    crawl,
  );

  const filteredRawData = series.data.filter((s) =>
    s.sets.includes(currentCrawlType),
  );

  // Unique to source: total from source - overlap
  // Unique to crawl type: total from crawl type - overlap
  // Overlap: overlap
  // Missing: total urls - total from source - total from crawl type - overlap
  // eslint-disable-next-line fp/no-mutating-methods
  const filteredNormalizedData = filteredRawData
    .reduce<{ crawlType: CrawlType; dataPoint: DataPoint }[]>(
      (accumulator, current) => {
        if (current.sets.length === 1) return accumulator;

        const crawlType: CrawlType | undefined = current.sets.find(
          (s) => s !== currentCrawlType,
        );
        if (!crawlType) return accumulator;

        const sourceValue =
          series.data.find((serie) =>
            serie.sets.every((set) => set === crawlType),
          )?.value ?? 0;

        const crawlTypeValue =
          series.data.find((serie) =>
            serie.sets.every((set) => set === currentCrawlType),
          )?.value ?? 0;

        const uniqueToSource = sourceValue - current.value;
        const uniqueToCrawlType = crawlTypeValue - current.value;
        const totalUrls = current.totalUrls ?? 0;

        const dataPoint: DataPoint = {
          uniqueToSource,
          uniqueToCrawlType,
          overlap: current.value,
          missing:
            totalUrls - uniqueToSource - uniqueToCrawlType - current.value,
        };

        return [...accumulator, { crawlType, dataPoint }];
      },
      [],
    )
    .sort(
      (a, b) =>
        crawlTypeOrder.indexOf(a.crawlType) -
        crawlTypeOrder.indexOf(b.crawlType),
    );

  /**
   *
   * @author Alex Sanchez
   * We do all predicate values set to `true` and only change the predicate key to be equal or not equal.
   * This way the falsy value (nulls) are also considered as with predicate value `false` it's a strict boolean
   * check and don't include the null values.
   */
  function extractSets(point: Point):
    | {
        crawlType: CrawlType;
        predicateKey: ConnectionPredicate;
      }[]
    | undefined {
    const secondaryCrawlType = filteredNormalizedData[point.index].crawlType;

    switch (point.series.index) {
      case 3:
        return [
          {
            crawlType: secondaryCrawlType,
            predicateKey: ConnectionPredicate.Eq,
          },
          {
            crawlType: currentCrawlType,
            predicateKey: ConnectionPredicate.Ne,
          },
        ];
      case 2:
        return [
          {
            crawlType: secondaryCrawlType,
            predicateKey: ConnectionPredicate.Eq,
          },
          {
            crawlType: currentCrawlType,
            predicateKey: ConnectionPredicate.Eq,
          },
        ];
      case 1:
        return [
          {
            crawlType: secondaryCrawlType,
            predicateKey: ConnectionPredicate.Ne,
          },
          {
            crawlType: currentCrawlType,
            predicateKey: ConnectionPredicate.Eq,
          },
        ];
      default:
        return [
          {
            crawlType: secondaryCrawlType,
            predicateKey: ConnectionPredicate.Ne,
          },
          {
            crawlType: currentCrawlType,
            predicateKey: ConnectionPredicate.Ne,
          },
        ];
    }
  }

  function getFilterName(crawlType: CrawlType): string {
    const suffix = crawlType === CrawlType.Web ? "Crawl" : "";
    return `foundIn${crawlType.replace(/\s/g, "")}${suffix}`;
  }

  function generateUrl(point: Point): string {
    const sets = extractSets(point);

    const andFilters =
      sets?.map((set) => ({
        metricCode: getFilterName(set.crawlType),
        predicateKey: set.predicateKey,
        predicateValue: true,
      })) ?? [];

    return Routes.Report.getUrl({
      ...linkData,
      reportTemplateCode: "indexable_pages",
      reportTypeCode: "basic",
      filter: createConnectionFilter({
        or: [
          {
            and: andFilters,
          },
        ],
      }),
    });
  }

  const renderTooltipSource = (index: number): string => {
    if (index === 3) return t("vennDiagram.chart.uniqueToSource");
    if (index === 2) return t("vennDiagram.chart.overlap");
    if (index === 1)
      return t("vennDiagram.chart.uniqueToCrawlType", {
        sourceName: getCrawlTypeName(t, currentCrawlType),
      });

    return t("vennDiagram.chart.missingBoth");
  };

  const options: Highcharts.Options = {
    colors: props.visualisationColors,
    xAxis: {
      categories: filteredNormalizedData.map((data) =>
        t("vennDiagram.chart.sourceLabel", {
          sourceName: getCrawlTypeName(t, data.crawlType),
        }),
      ),
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          return this.value + "%";
        },
        style: {
          textOverflow: "none",
        },
      },
    },
    legend: {
      reversed: true,
    },
    plotOptions: {
      series: {
        stacking: "percent",
        cursor: "pointer",
        point: {
          events: {
            click: function (event) {
              const point = (event.point ?? event.target) as Highcharts.Point;
              const url = generateUrl(point);
              history.push(url);
            },
          },
        },
      },
    },
    series: [
      {
        name: t("vennDiagram.chart.missingBoth"),
        type: "bar",
        data: filteredNormalizedData.map(({ dataPoint }) => dataPoint.missing),
      },
      {
        name: t("vennDiagram.chart.uniqueToCrawlType", {
          sourceName: getCrawlTypeName(t, currentCrawlType),
        }),
        type: "bar",
        data: filteredNormalizedData.map(
          ({ dataPoint }) => dataPoint.uniqueToCrawlType,
        ),
      },
      {
        name: t("vennDiagram.chart.overlap"),
        type: "bar",
        data: filteredNormalizedData.map(({ dataPoint }) => dataPoint.overlap),
      },
      {
        name: t("vennDiagram.chart.uniqueToSource"),
        type: "bar",
        data: filteredNormalizedData.map(
          ({ dataPoint }) => dataPoint.uniqueToSource,
        ),
      },
    ],
    tooltip: {
      formatter: function () {
        const jsx = (
          <div className={tooltipClasses.container}>
            <div className={tooltipClasses.textContainer}>
              <div className={tooltipClasses.urlCountText}>
                {tUnits("url", { count: this.options.y ?? 0 })}
              </div>
              <div>{renderTooltipSource(this.series.index)}</div>
            </div>
          </div>
        );

        return renderToString(jsx);
      },
    },
    chart: {
      style: {
        fontSize: "1em",
        fontFamily: theme.typography.fontFamily,
      },
    },
    exporting: {
      csv: {
        columnHeaderFormatter: function (item?: { name?: string }) {
          return item?.name ?? "Report";
        },
      },
      chartOptions: {
        chart: {
          spacingTop: 20,
          spacingBottom: 20,
        },
      },
    },
  };

  return {
    options,
    seriesData: filteredRawData,
    missingMultipleSources: series.data.length < 2,
  };
}

import { Project, UpdateProjectInput } from "../../../../../graphql";
import { CrawlSafeguard } from "../types";

type QueryData = Pick<
  Project,
  "failureRateLimitEnabled" | "failureRateThreshold"
>;

export const formatCrawlSafeguardSettingsFrom = (
  data: QueryData | null | undefined,
): CrawlSafeguard => {
  return {
    enabled: data?.failureRateLimitEnabled ?? true,
    threshold: data?.failureRateThreshold ?? 80,
  };
};

export const formatCrawlSafeguardSettingsTo = (
  data: CrawlSafeguard,
): Pick<
  UpdateProjectInput,
  "failureRateLimitEnabled" | "failureRateThreshold"
> => {
  return {
    failureRateLimitEnabled: data.enabled,
    failureRateThreshold: data.threshold,
  };
};

import {
  AlertSettingCode,
  Location,
  LocationCode,
  UrlRewriteCaseOption,
  Crawl,
  ScheduleFrequency,
  ScheduleFrequencyCode,
  AlertSetting,
  Module,
  GetCrawlSettingsForSettingsQuery,
} from "../../../../graphql";
import { UserAgentType } from "../types";

export interface FormValues {
  compareToCrawl: "None" | "LastCrawl" | string;
  scope: ScopeSettings;
  spider: SpiderSettings;
  userAgent: UserAgentSettings;
  cookies: CookiesSettings;
  extraction: ExtractionSettings;
  test: TestSettings;
  report: ReportSetupSettings;
}

export interface ScopeSettings {
  domainScope: DomainScopeSettings;
  urlScope: UrlScopeSettings;
  resourceRestrictions: ResourceRestrictionsSettings;
  linkRestrictions: LinkRestrictionsSettings;
  redirectSettings: RedirectSettings;
  linkValidation: LinkValidationSettings;
}

export interface DomainScopeSettings {
  primaryDomain: string;
  crawlSubDomains: boolean;
  crawlHttpAndHttps: boolean;
  secondaryDomains: string[];
}

export interface UrlScopeSettings {
  includedUrls: string[];
  excludedUrls: string[];
  groups: PageGroupingSettings[];
}

export interface PageGroupingSettings {
  name: string;
  urlMatch: string;
  crawlSample: number;
}

export interface ResourceRestrictionsSettings {
  crawlNonHtmlUrls: boolean;
  crawlCssResources: InternalExternalType;
  crawlJsResources: InternalExternalType;
  crawlImageResources: InternalExternalType;
  ignoreInvalidSSLCertificate: boolean;
}

export enum ResourceMode {
  Internal = "Internal",
  External = "External",
  InternalExternal = "InternalExternal",
}

export interface InternalExternalType {
  enabled: boolean;
  mode: ResourceMode;
}

export interface RedirectSettings {
  followInternalRedirects: boolean;
  followExternalRedirects: boolean;
}

export interface LinkRestrictionsSettings {
  crawlFollowAnchorLinks: boolean;
  crawlNoFollowAnchorLinks: boolean;
  crawlCanonicalLinks: boolean;
  crawlPaginationLinks: boolean;
  crawlMobileAlternateLinks: boolean;
  crawlAmphtmlLinks: boolean;
  crawlHreflangLinks: boolean;
}

export interface LinkValidationSettings {
  disallowedLinks: boolean;
  excludedLinks: boolean;
  externalAnchorLinks: boolean;
  externalCanonicalLinks: boolean;
  externalPaginationLinks: boolean;
  externalMobileAltLinks: boolean;
  externalAmphtmlLinks: boolean;
  externalHreflangLinks: boolean;
}

export interface ExternalInternalValues {
  internal: boolean;
  external: boolean;
}

export interface SpiderSettings {
  startUrls: StartUrlsSettings;
  scriptRendering: ScriptRenderingSettings;
  crawlerIp: CrawlerIpSettings;
  mobileSite: MobileSiteSettings;
  robotsOverwrite: RobotsOverwriteSettings;
  stealthMode: StealthModeSettings;
  customRequestHeaders: CustomRequestHeaders;
  crawlSafeguard: CrawlSafeguard;
}

export interface StartUrlsSettings {
  startUrls: string[];
}

export interface ScriptRenderingSettings {
  enable: boolean;
  blockAdScripts: boolean;
  blockAnalyticsScripts: boolean;
  customRejections: string;
  customScript: string;
  customAccessibilityScript: string;
  externalResources: string[];
  flattenShadowDom: boolean;
  flattenIframes: boolean;
  renderTimeout: number;
  block3rdPartyCookies: boolean;
}

export interface CrawlerIpSettings {
  location: LocationCode;
}

export interface UserAgentSettings {
  code: "Custom" | string;
  userAgentIsMobile: boolean | null;
  string: string;
  token: string;
  viewportWidth?: number;
  viewportHeight?: number;
}

export interface MobileSiteSettings {
  enable: boolean;
  homePage: string;
  pattern: string;
  agent: "None" | "Custom" | string;
  customFull: string;
  customShort: string;
  userAgentIsMobile: boolean | null;
  viewportWidth?: number | null;
  viewportHeight?: number | null;
}

export interface StealthModeSettings {
  enable: boolean;
}

export interface CustomRequestHeaders {
  headers: RequestHeader[];
}

export interface CrawlSafeguard {
  enabled: boolean;
  threshold: number;
}

export interface RequestHeader {
  key: string;
  value: string;
}

export interface CookiesSettings {
  cookies: CookieSettings[];
}

export interface CookieSettings {
  key: string;
  value: string;
}

export interface ExtractionSettings {
  customExtraction: CustomExtractionSettings;
}

export interface CustomExtractionSettings {
  rules: CustomExtractionRule[];
}

export interface CustomExtractionRule {
  label: string;
  pattern: string;
  matchNumber: number;
  strip: string;
  cleanHtmlTags: boolean;
  reportTemplateCode?: string;
}

export interface TestSettings {
  testSiteDomain: TestSiteDomainSettings;
  customDNS: CustomDNSSettings;
  authentication: AuthenticationSettings;
  removeUrlParams: RemoveUrlParamsSettings;
  urlRewriting: UrlRewritingSettings;
}

export interface RobotsOverwriteSettings {
  robots: string;
  renderingRobotsEnabled: boolean;
  ignoreRobotsForNavigationRequests: boolean;
  enable: boolean;
}

export interface TestSiteDomainSettings {
  domain: string;
  enable: boolean;
}

export interface CustomDNSSettings {
  customDns: CustomDNS[];
}

export interface AuthenticationSettings {
  userName: string;
  password: string;
}

export interface CustomDNS {
  hostName: string;
  ipAddress: string;
}

export interface RemoveUrlParamsSettings {
  params: string[];
}

export interface UrlRewritingSettings {
  rules: UrlRewriteRule[];
  useRewriteRules: boolean;
  stripFragments: boolean;
}

export interface UrlRewriteRule {
  matchFrom: string;
  matchTo: string;
  caseOption: "None" | "Default" | UrlRewriteCaseOption;
}

export interface ReportSetupSettings {
  saveHTMLAndScreenshots: SaveHTMLAndScreenshotsSettings;
  apiCallback: ApiCallbackSettings;
  emailAlerts: EmailAlertsSettings;
  report: ReportSettings;
}

export interface SaveHTMLAndScreenshotsSettings {
  saveHTML: boolean;
  saveScreenshot: boolean;
}
export interface ApiCallbackSettings {
  url: string;
  headers: CallbackHeader[];
}

export interface CallbackHeader {
  key: string;
  value: string;
}

export interface EmailAlertsSettings {
  settingsCode: AlertSettingCode;
  emails: string[];
}

export interface ReportSettings {
  maxTitleWidth: string;
  minTitleWidth: string;
  minDescriptionLength: string;
  maxDescriptionLength: string;
  maxHtmlSize: string;
  maxLinks: string;
  thinPageThreshold: string;
  emptyPageThreshold: string;
  maxExternalLinks: string;
  maxContentSize: string;
  maxUrlLength: string;
  maxFetchTime: string;
  duplicatePrecision: string;
  maxRedirections: string;
  lowLogSummaryRequests: string;
  highLogSummaryRequests: string;
}

export interface SubmitCallback {
  subscibe: (callback: () => void) => void;
  unsubscibe: (callback: () => void) => void;
  invoke: () => void;
}

export interface FinishedCrawl {
  id: string;
  createdAt: Date;
}

export interface ContextValues {
  getProjectSettings: (projectId: string) => Promise<FormValues | undefined>;
  accountId: string;
  projectId: string;
  projectName: string;
  primaryDomain: string;
  finishedCrawls: FinishedCrawl[];
  lastCrawl: Pick<Crawl, "id" | "statusEnum"> | undefined;
  lastFinishedCrawlId: string;
  hasCrawlInProgress: boolean;
  hasCrawlUnarchiving: boolean;
  hasPausedCrawl: boolean;
  scheduleFrequencies: Array<Pick<ScheduleFrequency, "code" | "name">>;
  initialSchedule: Schedule | undefined;
  accountHasJSRenderingEnabled: boolean;
  isPlanLight: boolean;
  isPlanLightPlus: boolean;
  locations: Array<Pick<Location, "name" | "enabled" | "code" | "type">>;
  userAgents: Array<UserAgentType>;
  alertSettings: Array<Pick<AlertSetting, "code" | "name">>;
  crawlerIpAddresses: string[];
  maximumRenderTimeout: number;
  userAgentSuffix: string;
  module: Pick<
    Module,
    | "code"
    | "userAgentSuffixEnabled"
    | "viewportHeightDesktop"
    | "viewportHeightMobile"
    | "viewportWidthDesktop"
    | "viewportWidthMobile"
  >;
  accessibilityContainer: { id: string; name: string } | undefined;

  globalContainers:
    | NonNullable<
        GetCrawlSettingsForSettingsQuery["getGlobalCustomMetricContainers"]["nodes"]
      >
    | undefined;
}

export interface UserAgentData {
  code: string;
  name: string;
  string: string;
  token: string;
  viewportWidth: number;
  viewportHeight: number;
  isMobile: boolean;
  isDeprecated: boolean;
  isPublic: boolean;
}

export interface ValidationErrors {
  groups: {
    name?: string;
    settings: {
      name?: string;
      errors: string[];
    }[];
  }[];
}

export interface Schedule {
  id: string;
  frequency: ScheduleFrequencyCode;
  date: Date;
}

import { ReportCategoryChartConfig } from "../types";
import { getAmpValidationChart } from "../../../../_common/charts/config/getAmpValidationChart";
import { getMobileConfigurationChart } from "../../../../_common/charts/config/getMobileConfigurationChart";
import { getMobileConfigurationTrend } from "../../../../_common/charts/config/getMobileConfigurationTrend";
import { getMobileIssuesChart } from "../../../../_common/charts/config/getMobileIssuesChart";

export const generateIndexabilityMobileIndexabilityCategoryItems: ReportCategoryChartConfig =
  {
    includeTopChanges: true,
    mainCharts: [
      getMobileConfigurationChart,
      getMobileConfigurationTrend,
      getMobileIssuesChart,
      getAmpValidationChart,
    ],
  };

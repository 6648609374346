import { Typography, useTranslation } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { FastField } from "formik";
import React from "react";
import { Label } from "../../../../_common/components/Label";

import { CheckboxWithLabel } from "../../../../_common/forms/CheckboxWithLabel";
import { TextFieldWithOnBlurUpdate } from "../../components/CustomFields";
import { withDisplayValue } from "../../components/TextFieldInput";
import { SettingControlFactory } from "../useAdvancedControls";

export const getCrawlSafeguardControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("settings.crawlSafeguard.title"),
  path: "crawlSafeguard",
  // eslint-disable-next-line react/display-name
  control: () => <CrawlSafeguard />,
  testId: "crawl-settings-advanced-crawl-safeguard",
  activeValues: ["crawlSafeguard"],
  textForSearch: createTextForSearch([
    t("settings.crawlSafeguard.title"),
    t("settings.crawlSafeguard.description"),
    t("settings.crawlSafeguard.thresholdLabel"),
  ]),
});

const useStyles = makeStyles((theme) => ({
  fieldLabel: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  fieldInput: {
    width: "250px",
  },
}));

export const CrawlSafeguard = React.memo(CrawlSafeguardInner);

function CrawlSafeguardInner(): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  return (
    <>
      <Typography variant="caption" style={{ marginBottom: "8px" }}>
        {t("settings.crawlSafeguard.description")}
      </Typography>
      <FastField
        name="spider.crawlSafeguard.enabled"
        component={CheckboxWithLabel}
        type="checkbox"
        Label={{
          label: (
            <Label
              label={t("crawlSettings:settings.crawlSafeguard.enabledLabel")}
            />
          ),
        }}
        data-testid="flatten-shadow-dom"
      />
      <Label
        label={t("crawlSettings:settings.crawlSafeguard.thresholdLabel")}
        className={classes.fieldLabel}
        htmlFor="crawlSafeguardThreshold"
      />
      <FieldEntry
        id="crawlSafeguardThreshold"
        name="spider.crawlSafeguard.threshold"
        type="number"
        valueFormater={(value) =>
          t("crawlSettings:settings.reportSettings.valuePercentage", { value })
        }
        pattern={/^(\+?\d+)?$/}
        data-testid="crawl-safeguard-threshold"
      />
    </>
  );
}

function FieldEntry({
  valueFormater,
  ...props
}: Partial<Parameters<typeof TextFieldWithOnBlurUpdate>[0]> & {
  name: string;
  pattern: RegExp;
  valueFormater?: (value: string) => string;
}): JSX.Element {
  const classes = useStyles();

  return (
    <FastField
      {...props}
      component={TextFieldWithOnBlurUpdate}
      hiddenLabel
      variant="outlined"
      className={classes.fieldInput}
      InputProps={{
        inputComponent: valueFormater
          ? withDisplayValue(valueFormater)
          : undefined,
      }}
    />
  );
}

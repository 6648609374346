import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityTextAlternativesAChart } from "../../../../../_common/charts/config/getAccessibilityTextAlternativesAChart";
import { getAccessibilityTextAlternativesATrend } from "../../../../../_common/charts/config/getAccessibilityTextAlternativesATrend";

export const generateAccessibilityTextAlternativesACategoryItems: ReportCategoryChartConfig =
  {
    includeTopChanges: true,
    mainCharts: [
      getAccessibilityTextAlternativesAChart,
      getAccessibilityTextAlternativesATrend,
    ],
  };

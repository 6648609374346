import { ReportCategoryChartConfig } from "../types";
import { getBacklinkIssuesChart } from "../../../../_common/charts/config/getBacklinkIssuesChart";
import { getBacklinkIssuesTrend } from "../../../../_common/charts/config/getBacklinkIssuesTrend";
import { getBacklinksBreakdownChart } from "../../../../_common/charts/config/getBacklinksBreakdownChart";
import { getBacklinksDistribution } from "../../../../_common/charts/config/getBacklinksDistribution";
import { getBacklinksDistributionTrend } from "../../../../_common/charts/config/getBacklinksDistributionTrend";
import { getPagesWithBacklinksDepthChart } from "../../../../_common/charts/config/getPagesWithBacklinksDepthChart";

export const generateRankabilityAuthorityCategory: ReportCategoryChartConfig = {
  includeTopChanges: true,
  mainCharts: [
    getBacklinksBreakdownChart,
    getPagesWithBacklinksDepthChart,
    getBacklinksDistribution,
    getBacklinksDistributionTrend,
    getBacklinkIssuesChart,
    getBacklinkIssuesTrend,
  ],
};

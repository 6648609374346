import { TFunction } from "i18next";
import { useTooltipStyles } from "../utils/useTooltipStyles";
import { doesPointContainAdditionalTooltipLines } from "./utils";
import { ReportTemplateUnitFormatter } from "../../locale/format-api-enum/useReportTemplateUnitFormatter";
import { hasUnitProperty } from "../utils/hasUnitProperty";

type Props = {
  formatter: Highcharts.Point;
  t: TFunction<"units">;
  formatUnit: ReportTemplateUnitFormatter;
  classes: ReturnType<typeof useTooltipStyles>;
  showPercentageInSeriesTooltip?: boolean;
};

export const PointTooltip = ({
  formatter,
  t,
  formatUnit,
  classes,
  showPercentageInSeriesTooltip,
}: Props): JSX.Element => {
  return (
    <div className={classes.container}>
      {showPercentageInSeriesTooltip && formatter.percentage ? (
        <span className={classes.percentage}>
          {t("percent", { count: formatter.percentage / 100 })}
        </span>
      ) : null}
      <div className={classes.textContainer}>
        <div className={classes.urlCountText}>
          {formatUnit(
            formatter.y || 0,
            hasUnitProperty(formatter.series.userOptions)
              ? formatter.series.userOptions.unit
              : "",
          )}
        </div>
        {doesPointContainAdditionalTooltipLines(formatter)
          ? formatter.additionalTooltipLines.map((line) => (
              <div key={line}>{line}</div>
            ))
          : null}
        <div>{formatter.series.name}</div>
      </div>
    </div>
  );
};

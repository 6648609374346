import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilitySensoryAndVisualBPChart } from "../../../../../_common/charts/config/getAccessibilitySensoryAndVisualBPChart";
import { getAccessibilitySensoryAndVisualBPTrend } from "../../../../../_common/charts/config/getAccessibilitySensoryAndVisualBPTrend";

export const generateAccessibilitySensoryAndVisualBPCategoryItems: ReportCategoryChartConfig =
  {
    includeTopChanges: true,
    mainCharts: [
      getAccessibilitySensoryAndVisualBPChart,
      getAccessibilitySensoryAndVisualBPTrend,
    ],
  };

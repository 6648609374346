/* eslint-disable react/display-name */
import React from "react";
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import {
  GetTaskReportTrendDataDocument,
  ReportStatTrendItem,
} from "../../../graphql";
import { Routes } from "../../routing/routes";
import { RunNewCrawlMessage } from "../components/chart-messages/RunNewCrawlMessage";
import { Theme } from "@material-ui/core";
import { DateFormatter } from "@lumar/shared";
import { getTaskCustomReportTrendSeriesConfig } from "./getTaskCustomReportTrendSeriesConfig";

export function generateTaskReportTrendChart(taskId: string) {
  return function (
    t: TFunction<"charts">,
    theme: Theme,
    formatter: DateFormatter,
  ): ChartConfigItem {
    return {
      inputSource: "GqlQuery",
      visualisationFamily: "MultiDimensionalSeries",
      visualisationType: VisualisationTypes.Area,
      visualisationColors: [theme.palette.singleLineChart.color],
      additionalVisualisationTypes: [
        VisualisationTypes.StackedArea,
        VisualisationTypes.FullStackedArea,
      ],
      gqlDocument: GetTaskReportTrendDataDocument,
      gqlVariables: () => {
        return {
          taskId,
        };
      },
      title: (reports) =>
        t("taskTrend.title", {
          taskName: reports[0]?.reportTemplateName,
        }),
      series: (reports, { accountId, projectId }) => {
        const taskCustomReportSeriesConfig =
          getTaskCustomReportTrendSeriesConfig(theme);
        return reports
          .filter((r) => !r.reportTemplateCode.startsWith("task_new"))
          .map((report) => {
            const type = report.reportTemplateCode.split("_")[1] ?? "all";
            const config = taskCustomReportSeriesConfig[type];
            const name =
              getTaskCustomReportTrendName(type, t) ??
              report.reportTemplateName;
            return {
              ...config,
              name,
              reportTemplateCode: report.reportTemplateCode,
              data: (report.trend ?? [])
                .filter(
                  (
                    trend,
                  ): trend is ReportStatTrendItem & {
                    createdAt: string;
                  } => !!trend?.createdAt,
                )
                .map((trend) => {
                  const createdAt = new Date(trend.createdAt);
                  return {
                    x: createdAt,
                    y: trend.basic ?? 0,
                    url: Routes.TaskReport.getUrl({
                      accountId,
                      projectId,
                      crawlId: String(trend.crawlId),
                      taskId,
                      type,
                    }),
                    reportTemplateCode: report.reportTemplateCode,
                    additionalTooltipLines: [
                      formatter(createdAt, {
                        dateStyle: "medium",
                        timeStyle: "short",
                      }),
                    ],
                  };
                }),
            };
          });
      },
      incompleteSeriesTemplate: (
        <RunNewCrawlMessage message={t("runNewCrawlForTrendMessage")} />
      ),
      testAttributePrefix: "report-trend-chart",
    };
  };
}

function getTaskCustomReportTrendName(
  type: string,
  t: TFunction<"charts">,
): string | undefined {
  if (type === "all") {
    return t("taskTrend.all");
  }

  if (type === "resolved") {
    return t("taskTrend.resolved");
  }

  if (type === "unresolved") {
    return t("taskTrend.unresolved");
  }

  return;
}

/* eslint-disable react/display-name */
import { Typography } from "@material-ui/core";
import { Trans } from "react-i18next";
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { GetTopSegmentsChartDataDocument } from "../../../graphql";
import { CrawlContextValueData } from "../../../crawl-overview/CrawlContext";

export function generateTopSegmentsChart(reportTemplateCode: string) {
  return function (t: TFunction<"charts">): ChartConfigItem {
    return {
      inputSource: "GqlQuery",
      visualisationFamily: "MultiSeries",
      visualisationType: VisualisationTypes.Bar,
      title: (reports) => {
        if (reports[0]) {
          const reportName = reports[0]?.reportTemplateName;
          return t("topSegments.title", { reportName });
        }
        return t("topSegments.title", { reportName: "" });
      },
      description: (_, reports) => {
        const reportName = reports[0]?.reportTemplateName;
        return (
          <Trans ns="charts" i18nKey="topSegments.description">
            <Typography paragraph variant="inherit">
              The top five segments that have the most URLs in the{" "}
              {{ reportName }} report.
            </Typography>
            <Typography variant="inherit">
              <i>Note:</i> URLs can belong to more than one segment.
            </Typography>
          </Trans>
        );
      },
      gqlDocument: GetTopSegmentsChartDataDocument,
      gqlVariables: (crawlContext: CrawlContextValueData) => {
        return {
          crawlId: crawlContext.crawl.rawID,
          reportTemplateCode,
        };
      },
      reportStatFilter(report) {
        return !!report.segmentId;
      },
      reportStatsLimit: 5,
      reportStatsOrderBy: {
        field: "basic",
        direction: "desc",
      },
      serieName: (report) => {
        return report.segment?.name ?? "";
      },
      count: (report) => {
        return report.basic ?? 0;
      },
      nameInTooltip: (report) => {
        return report.reportTemplateName;
      },
      testAttributePrefix: "top-segments-chart",
    };
  };
}

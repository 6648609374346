/* eslint-disable fp/no-this, react/display-name */
import Highcharts from "highcharts";
import React from "react";

import { Box, makeStyles, useTheme } from "@material-ui/core";
import { merge, unescape } from "lodash";
import { renderToString } from "react-dom/server";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ReportTemplateUnit } from "../../../graphql";
import { useReportTemplateUnitFormatter } from "../../locale/format-api-enum/useReportTemplateUnitFormatter";
import { useChartDataContext } from "../components/chart-components/ChartDataContext";
import { ChartRef, HighchartsChart } from "../components/HighchartsChart";
import { VisualisationTypes } from "../types/ChartConfig";
import { hasUnitProperty } from "../utils/hasUnitProperty";
import { useTooltipStyles } from "../utils/useTooltipStyles";
import { MultiDimensionalSeriesChartProps } from "./MultiDimensionalSeriesChart";
import {
  doesPointContainAdditionalTooltipLines,
  isPointWithUrl,
  useSeriesData,
} from "./utils";
import { useBrand } from "@lumar/shared";

const useStyles = makeStyles({
  wrapper: {
    width: "100%",
    height: "100%",
  },
  container: {
    height: "100%",
  },
});

export const LineOrAreaChart = React.forwardRef<
  ChartRef,
  MultiDimensionalSeriesChartProps
>(function Chart(
  {
    visualisationType,
    visualisationColors,
    visualisationFillColors,
    title,
    icon,
    xAxisLabel,
    yAxisMax,
    yAxisOptions,
    multiDimensionalSeries: series,
  },
  ref,
): JSX.Element {
  const brand = useBrand();
  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();
  const history = useHistory();
  const { t } = useTranslation("units");
  const { crawl, project, segmentName } = useChartDataContext();
  const formatUnit = useReportTemplateUnitFormatter();

  const isPercentageStackedAreaChart =
    visualisationType === VisualisationTypes.FullStackedArea;

  const xAxisValue =
    series.length && series[0].data.length ? series[0].data[0].x : 0;
  const xAxisType: Highcharts.AxisTypeValue =
    xAxisValue instanceof Date ? "datetime" : "linear";

  const shouldApplyReportColor =
    !visualisationColors && brand.featureAvailability.chartReportSerieColor;

  const memoizedSeries = useSeriesData(
    series,
    visualisationType,
    shouldApplyReportColor,
    visualisationFillColors,
  );

  const theme = useTheme();

  const defaultYAxisOptions: Highcharts.YAxisOptions = {
    min: 0,
    max: yAxisMax,
    allowDecimals: false,
    title: {
      text: null,
    },
    labels: {
      formatter: isPercentageStackedAreaChart
        ? function () {
            return `${this.value}%`;
          }
        : undefined,
    },
  };

  const yAxisOptionsWithDefaults = Array.isArray(yAxisOptions)
    ? yAxisOptions.map((option) => merge({}, defaultYAxisOptions, option))
    : merge({}, defaultYAxisOptions, yAxisOptions);

  const options: Highcharts.Options = {
    chart: {
      spacingTop: 10,
      spacingRight: 5,
      spacingLeft: 5,
      style: {
        fontSize: "1em",
        fontFamily: theme.typography.fontFamily,
      },
      events: {
        render: function () {
          this.series.forEach((series) => {
            if (series.legendItem?.symbol) {
              series.legendItem.symbol.attr({
                opacity: 0,
              });
            }
          });
        },
      },
    },
    ...(visualisationColors ? { colors: visualisationColors } : {}),
    xAxis: {
      type: xAxisType,
      allowDecimals: false,
      title: {
        text: xAxisLabel,
      },
    },
    yAxis: yAxisOptionsWithDefaults,
    series: memoizedSeries,
    legend: {
      itemMarginBottom: 7,
      itemStyle: {
        width: 250,
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
    },
    plotOptions: {
      series: {
        cursor: "pointer",
        legendSymbol: "lineMarker",
        marker: {
          enabled: isPercentageStackedAreaChart
            ? false
            : (series[0]?.data.length ?? 0) < 20,
          lineColor: theme.palette.grey[50],
          lineWidth: 2,
        },
        point: {
          events: {
            click: function (event) {
              const point = (event.point ?? event.target) as Highcharts.Point;

              if (isPointWithUrl(point) && point.url) {
                const { url } = point;

                if (event.ctrlKey || event.metaKey) {
                  window.open(url, "_blank", "noreferrer");
                } else {
                  history.push(url);
                }
              }
            },
          },
        },
      },
      area: {
        stacking:
          visualisationType === VisualisationTypes.StackedArea
            ? "normal"
            : visualisationType === VisualisationTypes.FullStackedArea
              ? "percent"
              : undefined,
      },
    },
    tooltip: {
      formatter: function () {
        return renderToString(
          <div className={tooltipClasses.container}>
            {this.percentage ? (
              <span className={tooltipClasses.percentage}>
                {t("percent", { count: this.percentage / 100 })}
              </span>
            ) : null}
            <div className={tooltipClasses.textContainer}>
              <div className={tooltipClasses.urlCountText}>
                {formatUnit(
                  this.y || 0,
                  hasUnitProperty(this.series.userOptions)
                    ? this.series.userOptions.unit
                    : ReportTemplateUnit.UrLs,
                )}
              </div>
              {doesPointContainAdditionalTooltipLines(this)
                ? this.additionalTooltipLines.map((line) => (
                    <div key={line}>{line}</div>
                  ))
                : null}
              <div>{unescape(this.series.name)}</div>
            </div>
          </div>,
        );
      },
    },
    exporting: {
      chartOptions: {
        chart: {
          spacingTop: 20,
          spacingRight: 20,
          spacingLeft: 20,
        },
      },
    },
  };

  return (
    <Box flexGrow={1} className={classes.wrapper}>
      <HighchartsChart
        options={options}
        ref={ref}
        title={title}
        icon={icon}
        segmentName={segmentName}
        projectName={project?.name}
        primaryDomain={project?.primaryDomain}
        crawlCreatedAt={crawl?.createdAt}
      />
    </Box>
  );
});

import { ReportCategoryChartConfig } from "../types";
import { getBrokenLinksChart } from "../../../../_common/charts/config/getBrokenLinksChart";
import { getBrokenLinksTrend } from "../../../../_common/charts/config/getBrokenLinksTrend";
import { getLinksBreakdownChart } from "../../../../_common/charts/config/getLinksBreakdownChart";
import { getLinksBreakdownTrend } from "../../../../_common/charts/config/getLinksBreakdownTrend";
import { getOrphanedPagesReportChart } from "../../../../_common/charts/config/getOrphanedPagesReportChart";
import { getOrphanedPagesReportTrend } from "../../../../_common/charts/config/getOrphanedPagesReportTrend";

export const generateCrawlabilityInternalLinksCategoryChartConfigItems: ReportCategoryChartConfig =
  {
    includeTopChanges: true,
    mainCharts: [
      getLinksBreakdownChart,
      getLinksBreakdownTrend,
      getBrokenLinksChart,
      getBrokenLinksTrend,
      getOrphanedPagesReportChart,
      getOrphanedPagesReportTrend,
    ],
  };

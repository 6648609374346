/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography } from "@lumar/shared";
import { useMultipointTooltipStyles } from "../utils/useTooltipStyles";
import React from "react";
import { TFunction } from "i18next";

type Props = {
  formatter: Highcharts.Point;
  classes: ReturnType<typeof useMultipointTooltipStyles>;
  formatTitle?: (context: Highcharts.Point) => string;
  t: TFunction<"units">;
};

export const MultipointTooltip = ({
  formatter,
  classes,
  formatTitle,
  t,
}: Props): JSX.Element => (
  <div className={classes.container}>
    {formatTitle !== undefined && (
      <Typography style={{ fontWeight: 700 }}>
        {formatTitle(formatter)}
      </Typography>
    )}
    <div className={classes.grid}>
      <React.Fragment key={formatter.key}>
        <Typography className={classes.value}>
          {t("compactUrl", { count: formatter.y ?? undefined })}
        </Typography>
        <a href={(formatter as any).url} className={classes.link}>
          <Typography className={classes.name}>
            {formatter.series.name}
          </Typography>
        </a>
      </React.Fragment>
    </div>
  </div>
);

import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityTimeAndMediaAChart } from "../../../../../_common/charts/config/getAccessibilityTimeAndMediaAChart";
import { getAccessibilityTimeAndMediaATrend } from "../../../../../_common/charts/config/getAccessibilityTimeAndMediaATrend";

export const generateAccessibilityTimeAndMediaACategoryItems: ReportCategoryChartConfig =
  {
    includeTopChanges: true,
    mainCharts: [
      getAccessibilityTimeAndMediaAChart,
      getAccessibilityTimeAndMediaATrend,
    ],
  };

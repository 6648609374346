import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityLanguageAChart } from "../../../../../_common/charts/config/getAccessibilityLanguageAChart";
import { getAccessibilityLanguageATrend } from "../../../../../_common/charts/config/getAccessibilityLanguageATrend";

export const generateAccessibilityLanguageACategoryItems: ReportCategoryChartConfig =
  {
    includeTopChanges: true,
    mainCharts: [
      getAccessibilityLanguageAChart,
      getAccessibilityLanguageATrend,
    ],
  };

import { Theme } from "@material-ui/core";

export function getTaskCustomReportTrendSeriesConfig(theme: Theme): {
  [key: string]: { color: string; fillColor: string };
} {
  return {
    all: {
      color: theme.palette.blue[500],
      fillColor: theme.palette.blue[200],
    },
    unresolved: {
      color: theme.palette.red[500],
      fillColor: theme.palette.red[200],
    },
    resolved: {
      color: theme.palette.green[500],
      fillColor: theme.palette.green[200],
    },
  };
}

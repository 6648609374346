import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityIssuesandInstancesAATrend } from "../../../../../_common/charts/config/getAccessibilityIssuesandInstancesAATrend";
import { getAccessibilityLevelAAChart } from "../../../../../_common/charts/config/getAccessibilityLevelAAChart";
import { getAccessibilityLevelAATrend } from "../../../../../_common/charts/config/getAccessibilityLevelAATrend";
import { getAccessibilityPagesWithIssuesLevelAAChart } from "../../../../../_common/charts/config/getAccessibilityPagesWithIssuesLevelAAChart";
import { getAccessibilityPagesWithIssuesLevelAATrend } from "../../../../../_common/charts/config/getAccessibilityPagesWithIssuesLevelAATrend";

export const generateAccessibilityLevelAACategoryItems: ReportCategoryChartConfig =
  {
    includeTopChanges: true,
    mainCharts: [
      getAccessibilityIssuesandInstancesAATrend,
      getAccessibilityPagesWithIssuesLevelAAChart,
      getAccessibilityPagesWithIssuesLevelAATrend,
      getAccessibilityLevelAAChart,
      getAccessibilityLevelAATrend,
    ],
  };

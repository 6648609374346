import { ReportCategoryChartConfig } from "../types";
import { getAllPagesbyStatusCode } from "../../../../_common/charts/config/getAllPagesbyStatusCode";
import { getAvailabilityChart } from "../../../../_common/charts/config/getAvailabilityChart";
import { getAvailabilityTrend } from "../../../../_common/charts/config/getAvailabilityTrend";
import { getHttpStatusDepthChart } from "../../../../_common/charts/config/getHttpStatusDepthChart";
import { getNon200PagesChart } from "../../../../_common/charts/config/getNon200PagesChart";
import { getNon200PagesTrend } from "../../../../_common/charts/config/getNon200PagesTrend";

export const generateAvailabilityCategoryItems: ReportCategoryChartConfig = {
  includeTopChanges: true,
  mainCharts: [
    getAvailabilityChart,
    getAvailabilityTrend,
    getNon200PagesChart,
    getNon200PagesTrend,
    getHttpStatusDepthChart,
    getAllPagesbyStatusCode,
  ],
};

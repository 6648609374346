import { ReportCategoryChartConfig } from "./types";
import { getAnalyticsTrafficFunnel } from "../../../_common/charts/config/getAnalyticsTrafficFunnel";
import { getAnalyticsTrafficFunnelTrend } from "../../../_common/charts/config/getAnalyticsTrafficFunnelTrend";
import { getGscTrafficFunnel } from "../../../_common/charts/config/getGscTrafficFunnel";
import { getGscTrafficFunnelTrend } from "../../../_common/charts/config/getGscTrafficFunnelTrend";
import { getGscTrafficLevel } from "../../../_common/charts/config/getGscTrafficLevel";
import { getGscTrafficLevelTrend } from "../../../_common/charts/config/getGscTrafficLevelTrend";
import { getIssuesChart } from "../../../_common/charts/config/getIssuesChart";

export const generateTrafficCategoryConfigItems: ReportCategoryChartConfig = {
  includeTopChanges: true,
  mainCharts: [
    getIssuesChart,
    getGscTrafficFunnel,
    getGscTrafficFunnelTrend,
    getAnalyticsTrafficFunnel,
    getAnalyticsTrafficFunnelTrend,
    getGscTrafficLevel,
    getGscTrafficLevelTrend,
  ],
};
